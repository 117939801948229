<template>
  <v-col cols="12" class="pt-0 px-0">
    <v-row class="ma-0 mt-2">
      <v-col cols="12" class="px-0 d-flex justify-space-between">
        <v-chip :color="color" label :text-color="textColor">
          <v-icon left> mdi-label</v-icon>
          {{ title }}
        </v-chip>
        <v-btn fab small :color="color" :style="`color: ${textColor}`" @click="model.products.push({text: '', value: '', price: null})">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" class="px-0">
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr :style="{backgroundColor: color !== 'primary' ? color : 'var(--v-primary-base)'}">
                <th class="text-center text-uppercase" :style="`color: ${textColor}`">Product</th>
                <th class="text-center text-uppercase" :style="`color: ${textColor}`">Qty</th>
                <th class="text-center text-uppercase" :style="`color: ${textColor}`">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="productsListLoading">
                <td colspan="3" class="pa-0">
                  <v-skeleton-loader :loading="true" class="w-full"
                                     type="table-tbody, table-tbody"
                  ></v-skeleton-loader>
                </td>
              </tr>
              <template v-if="!productsListLoading && model.products.length">
                <tr v-for="(prod, index) in model.products" :key="index">
                  <td class="text-left pa-0 pa-3">
                    <v-combobox v-model="model.products[index]" :key="index+1" :items="productsList" dense outlined hide-details="auto" placeholder="Select Product"
                                    :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}" :rules="selectRules" return-object  @change="updateProduct(model.products[index])">
                    </v-combobox>
                  </td>
                  <td class="text-center pa-0 pa-3">
                    <v-text-field :key="index+1" class="box-shadow-none input-align-center px-0" type="number" min="0" solo
                                  v-model.number="model.products[index].price" dense outlined hide-details="auto" placeholder="0"
                                  @input="updateProduct(model.products[index])"/>
                  </td>
                  <td>
                    <v-btn :key="index+1" v-if="model.products.length !== 1" icon text color="error" @click="model.products.length > 0 && model.products.splice(index, 1)">
                      <v-icon dark>mdi-delete-circle</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template v-else-if="!productsListLoading && model.products.length === 0">
                <tr>
                  <td colspan="3" class="text-center text-caption pa-5">
                    No Products Data Found!.
                  </td>
                </tr>
              </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Additional Products'
    },
    color: {
      type: String,
      default: 'primary'
    },
    textColor: {
      type: String
    }
  },
  emits: ['productUpdate', 'priceUpdate'],
  data: (vm) => ({
    selectRules: [
      v => v && v.value ? vm.model.products.filter(item => item.value == v.value).length === 1 || 'Select other product, Because already this product was selected.' : true,
    ],
    selectedProd: null,
    model: { products: [] }
  }),
  computed: {
    ...mapGetters(['productsList', 'productsListLoading'])
  },
  watch: {
    products(val) {
      if (val.length) this.model.products = val;
    },
  },
  methods: {
    updateProduct(product) {
      const isCustomProduct = typeof product === 'string';
      if (isCustomProduct) {
        const customProductIndex = this.model.products.findIndex((mProduct) => mProduct == product);
        if (customProductIndex > -1) {
          const customProduct = { text: product, value: `${product}_${customProductIndex.toString()}`, price: null };
          this.model.products[customProductIndex] = customProduct;
          this.$emit('productUpdate', customProduct);
        }
      } else {
        if (this.model.products.filter(item=> item.value == product.value).length === 1) {
          this.$emit('productUpdate', product);
        }
      }
    }
  },
}
</script>


<style>

</style>